import {createSelector} from 'reselect'
import _ from 'lodash'

/* Init */
const initSelector = state => state.init
const demandeContratSelector = state => state.init.data.DemandeContrat || {}

/* State */
export const getStateContract = createSelector(
    initSelector,
    init => init.state
)

export const getToken = createSelector(
    initSelector,
    init => init.token
)

export const getDocumentsUrlInit = createSelector(
    initSelector,
    init => init.documents_url
)

export const getIsDirect = createSelector(
    initSelector,
    init => init.data.source === 'site' || init.data.source === 'concessionnaire'
)

export const getIsCourtier = createSelector(
    initSelector,
    init => init.data.source === 'courtier'
)

export const getSource = createSelector(
    initSelector,
    init => init.data.source
)

export const getIsMB = createSelector(
    initSelector,
    getSource,
    (init, source) => source === "site" && init.apporteur.codeBelair !== "43397"
)

export const getDataDevisInit = createSelector(
    initSelector,
    init => init.data.Devis || {}
)

export const getFormuleDevisInit = createSelector(
    getDataDevisInit,
    devis => devis.Formule
)

export const getUrlPDFDevisInit = createSelector(
    getDataDevisInit,
    devis => devis.UrlPDFDevis
)

export const getUrlPDFFicheConseilInit = createSelector(
    getDataDevisInit,
    devis => devis.UrlPDFFicheConseil
)


export const getLibelleFormuleDevisInit = createSelector(
    getDataDevisInit,
    devis => (devis.Formule && devis.Formule.LibelleFormule) || ""
)

export const getDataContratInit = createSelector(
    initSelector,
    init => init.data.Contrat || {}
)

export const getUrlPDFHamonContratInit = createSelector(
    getDataContratInit,
    contrat => contrat.UrlPDFHamon
)

export const getUrlPDFMandatSepaContratInit = createSelector(
    getDataContratInit,
    contrat => contrat.UrlPDFMandatSepa
)

export const getUrlPDFContratSepaContratInit = createSelector(
    getDataContratInit,
    contrat => contrat.UrlPDFContrat
)

export const getIsSendMailPaiement = createSelector(
    demandeContratSelector,
    contrat => contrat.ModePaiementComptant === "M"
)

export const getModePaiementComptantDemandeContratInit = createSelector(
    demandeContratSelector,
    contrat => contrat.ModePaiementComptant
)
export const getNomTitulaireDemandeContratInit = createSelector(
    demandeContratSelector,
    contrat => contrat.NomTitulaire
)
export const getadrIBANDemandeContratInit = createSelector(
    demandeContratSelector,
    contrat => contrat.adrIBAN
)
export const getvilleIBANDemandeContratInit = createSelector(
    demandeContratSelector,
    contrat => contrat.villeIBAN
)
export const getcpIBANDemandeContratInit = createSelector(
    demandeContratSelector,
    contrat => contrat.cpIBAN
)
export const getVINDemandeContratInit = createSelector(
    demandeContratSelector,
    contrat => contrat.VIN
)
export const getIBANDemandeContratInit = createSelector(
    demandeContratSelector,
    contrat => contrat.IBAN
)
export const getBICDemandeContratInit = createSelector(
    demandeContratSelector,
    contrat => contrat.BIC
)

/* Tiers payeur */
export const getTiersPayeurDemandeContratInit = createSelector(
    demandeContratSelector,
    contrat => contrat.TiersPayeur || {}
)
export const getTiersPayeurTypeDemandeContratInit = createSelector(
    getTiersPayeurDemandeContratInit,
    tiersP => tiersP.Type
)
export const getTiersPayeurLastNameDemandeContratInit = createSelector(
    getTiersPayeurDemandeContratInit,
    tiersP => tiersP.LastName
)
export const getTiersPayeurBirthNameDemandeContratInit = createSelector(
    getTiersPayeurDemandeContratInit,
    tiersP => tiersP.BirthName
)
export const getTiersPayeurFirstNameDemandeContratInit = createSelector(
    getTiersPayeurDemandeContratInit,
    tiersP => tiersP.FirstName
)
export const getTiersPayeurBirthDateDemandeContratInit = createSelector(
    getTiersPayeurDemandeContratInit,
    tiersP => tiersP.BirthDate
)
export const getTiersPayeurBirthCountryDemandeContratInit = createSelector(
    getTiersPayeurDemandeContratInit,
    tiersP => tiersP.BirthCountry
)
export const getTiersPayeurBirthCityDemandeContratInit = createSelector(
    getTiersPayeurDemandeContratInit,
    tiersP => tiersP.BirthCity
)
export const getTiersPayeurRelationshipDemandeContratInit = createSelector(
    getTiersPayeurDemandeContratInit,
    tiersP => tiersP.Relationship
)
export const getTiersPayeurThirdPayerReasonDemandeContratInit = createSelector(
    getTiersPayeurDemandeContratInit,
    tiersP => tiersP.ThirdPayerReason
)

/* Contrat */
export const getContratInit = createSelector(
    initSelector,
    init => init.data.Contrat || {}
)
export const getPoliceContratInit = createSelector(getContratInit, (contrat) => contrat.Police);

export const getListeDocumentsContratInit = createSelector(
    getContratInit,
    contrat => contrat.ListeDocuments
)

export const getPoliceContractInit = createSelector(
    getContratInit,
    contrat => contrat.Police
)

/* Signatures */
export const getSignaturesInit = createSelector(
    initSelector,
    init => init.signatures
)
export const getUrlSignaturesInit = createSelector(
    getSignaturesInit,
    signatures => signatures.length > 0 ? signatures[0].url : null
)

/* whitelabel */
export const getLogoWhitelabel = createSelector(
    initSelector,
    init => (init.whitelabel && init.whitelabel.logo_url) || null
)

export const getNameWhitelabel = createSelector(
    initSelector,
    init => init.whitelabel.name
)

/* Police */
export const getPoliceDemandeTarifInit = createSelector(
    initSelector,
    init => init.data.DemandeTarif.Police || {}
)
export const getFormuleChoisiePoliceDemandeTarifInit = createSelector(
    getPoliceDemandeTarifInit,
    police => police.FormuleChoisie !== undefined ? String(police.FormuleChoisie) : undefined
)

export const getTauxApporteur = createSelector(
    getPoliceDemandeTarifInit,
    police => police.TauxApporteur1
)

export const getHonorairesApporteur = createSelector(
    getPoliceDemandeTarifInit,
    police => police.Honoraires
)

export const getMotifAvenantInit = createSelector(
    getPoliceDemandeTarifInit,
    police => police.MotifAvenant
)

/* DemandeDevis */
export const getOptinNewsletterDemandeDevisInit = createSelector(
    initSelector,
    init => init.data.DemandeDevis && init.data.DemandeDevis.OptinNewsletter !== undefined ? String(init.data.DemandeDevis.OptinNewsletter) : undefined
)
export const getOptinUtilisationDonneesDemandeDevisInit = createSelector(
    initSelector,
    init => init.data.DemandeDevis && init.data.DemandeDevis.OptinUtilisationDonnees !== undefined ? String(init.data.DemandeDevis.OptinUtilisationDonnees) : undefined
)

/* Personne conducteur */
export const getConducteurDemandeTarif = createSelector(
    initSelector,
    init => _.find(init.data.DemandeTarif.ListePersonnes, {'TypePersonne': 'P', 'RoleConducteur': 'P'}) || {}
)

export const getCiviliteConducteurTarif = createSelector(
    getConducteurDemandeTarif,
    conducteur => conducteur.Civilite
)

export const getEmailConducteurTarif = createSelector(
    getConducteurDemandeTarif,
    conducteur => conducteur.Email
)

export const getDateNaissanceConducteurTarif = createSelector(
    getConducteurDemandeTarif,
    conducteur => conducteur.DateNaissance
)
export const getNomConducteurTarif = createSelector(
    getConducteurDemandeTarif,
    conducteur => conducteur.Nom
)
export const getPrenomConducteurTarif = createSelector(
    getConducteurDemandeTarif,
    conducteur => conducteur.Prenom
)

export const getBirthCountryConducteurTarif= createSelector(
    getConducteurDemandeTarif,
    conducteur => conducteur.BirthCountry
)
export const getBirthCityConducteurTarif= createSelector(
    getConducteurDemandeTarif,
    conducteur => conducteur.BirthCity
)
export const getBirthNameConducteurTarif= createSelector(
    getConducteurDemandeTarif,
    conducteur => conducteur.BirthName
)

export const getAdresse1ConducteurTarif = createSelector(
    getConducteurDemandeTarif,
    conducteur => conducteur.Adresse1
)
export const getAdresse2ConducteurTarif = createSelector(
    getConducteurDemandeTarif,
    conducteur => conducteur.Adresse2
)
export const getAdresse3ConducteurTarif = createSelector(
    getConducteurDemandeTarif,
    conducteur => conducteur.Adresse3
)
export const getVilleConducteurTarif = createSelector(
    getConducteurDemandeTarif,
    conducteur => conducteur.Ville
)
export const getCPConducteurTarif = createSelector(
    getConducteurDemandeTarif,
    conducteur => conducteur.CP
)

export const getTelPortableConducteurTarif = createSelector(
    getConducteurDemandeTarif,
    conducteur => conducteur.TelPortable === "" ? conducteur.TelFixe : conducteur.TelPortable
)

/* Société */
export const getPersonneMoraleDemandeTarifInit = createSelector(
    initSelector,
    init => _.find(init.data.DemandeTarif.ListePersonnes, ['TypePersonne', 'M']) || {}
)

export const getCivilitePersonneMoraleTarif= createSelector(
    getPersonneMoraleDemandeTarifInit,
    personneM => personneM.Civilite
)

export const getNomPersonneMoraleTarif= createSelector(
    getPersonneMoraleDemandeTarifInit,
    personneM => personneM.Nom
)

/* Vehicule */
export const getVehiculeDemandeTarifInit = createSelector(
    initSelector,
    init => init.data.DemandeTarif.Vehicule || {}
)

export const getDateMECVehiculeInit = createSelector(
    getVehiculeDemandeTarifInit,
    vehicule => vehicule.DateMEC
)
export const getDesignationVehiculeInit = createSelector(
    getVehiculeDemandeTarifInit,
    vehicule => vehicule.Designation
)
export const getGenreVehiculeVehiculeInit = createSelector(
    getVehiculeDemandeTarifInit,
    vehicule => vehicule.GenreVehicule
)
export const getValeurVehiculeVehiculeInit = createSelector(
    getVehiculeDemandeTarifInit,
    vehicule => vehicule.ValeurVehicule
)


/* Souscripteur */
export const getSouscripteurDemandeTarif = createSelector(
    initSelector,
    init => _.find(init.data.DemandeTarif.ListePersonnes, 'Souscripteur') || {}
)

export const getEmailSouscripteurTarif = createSelector(
    getSouscripteurDemandeTarif,
    souscripteur => souscripteur.Email
)

export const getCiviliteSouscripteurTarif = createSelector(
    getSouscripteurDemandeTarif,
    souscripteur => souscripteur.Civilite
)
export const getNomSouscripteurTarif = createSelector(
    getSouscripteurDemandeTarif,
    souscripteur => souscripteur.Nom
)
export const getPrenomSouscripteurTarif = createSelector(
    getSouscripteurDemandeTarif,
    souscripteur => souscripteur.Prenom
)
export const getAdresse1SouscripteurTarif = createSelector(
    getSouscripteurDemandeTarif,
    souscripteur => souscripteur.Adresse1
)
export const getAdresse2SouscripteurTarif = createSelector(
    getSouscripteurDemandeTarif,
    souscripteur => souscripteur.Adresse2
)
export const getAdresse3SouscripteurTarif = createSelector(
    getSouscripteurDemandeTarif,
    souscripteur => souscripteur.Adresse3
)
export const getVilleSouscripteurTarif = createSelector(
    getSouscripteurDemandeTarif,
    souscripteur => souscripteur.Ville
)
export const getCPSouscripteurTarif = createSelector(
    getSouscripteurDemandeTarif,
    souscripteur => souscripteur.CP
)
export const getTelPortableSouscripteurTarif = createSelector(
    getSouscripteurDemandeTarif,
    souscripteur => souscripteur.TelPortable
)
export const getNumPermisSouscripteurTarif = createSelector(
    getSouscripteurDemandeTarif,
    souscripteur => souscripteur.NumPermis
)

export const getNameSouscripteurDemandeTarif = createSelector(
    getSouscripteurDemandeTarif,
    souscripteur => `${souscripteur.Prenom} ${souscripteur.Nom}`
)

export const getAdressSouscripteurDemandeTarif = createSelector(
    getSouscripteurDemandeTarif,
    souscripteur => `${souscripteur.Adresse1} ${souscripteur.CP} ${souscripteur.Ville}`
)

export const getCrmAutoSouscripteurDemandeTarif = createSelector(
    getSouscripteurDemandeTarif,
    souscripteur => souscripteur.ValeurCrmAuto
)

export const getCrmMotoSouscripteurDemandeTarif = createSelector(
    getSouscripteurDemandeTarif,
    souscripteur => souscripteur.ValeurCrmMoto
)

export const getSignatureEmailDemandeContratInit = createSelector(
    getSouscripteurDemandeTarif,
    souscripteur => souscripteur && souscripteur.Email
)
export const getSignaturePhoneDemandeContratInit = createSelector(
    getSouscripteurDemandeTarif,
    souscripteur => souscripteur && souscripteur.TelPortable
)
export const getNumeroClientsSouscripteurDemandeContratInit = createSelector(
    getSouscripteurDemandeTarif,
    souscripteur => souscripteur && souscripteur.NumeroClient !== undefined ? String(souscripteur.NumeroClient) : undefined
)

/* Paiement */
const paiementSelector = state => state.init.payments

export const getPaiementHistory = createSelector(
    paiementSelector,
    paiement => _.last(paiement).history
)

export const getAmountPaymentsInit = createSelector(
    paiementSelector,
    paiement => _.last(paiement).amount
)

/* Devis */
const devisSelector = state => state.init.data.Devis

export const getIDDevisDevisInit = createSelector(
    devisSelector,
    devis => devis.IDDevis
)

/* Apporteur */

export const getApporteur2Init = createSelector(initSelector, (init) => init.apporteur2 || null);

export const getApporteur1Init = createSelector(initSelector, (init) => init.apporteur1 || null);

export const getEmailVenteApporteurInit = createSelector(
    initSelector,
    init => init.apporteur.emailVente
)

export const getGpcSouscriptionInit = createSelector(
    initSelector,
    init => init.apporteur.GpcSouscription
)

/* Tarif */
export const getTarifInit = createSelector(
    initSelector,
    init => init.data.Tarif || {}
)

export const getUrlPDFDispositionsGeneralesTarifInit = createSelector(
    getTarifInit,
    tarif => tarif.UrlPDFDispositionsGenerales
)

export const getUrlPDFDocumentInformationTarifInit = createSelector(
    getTarifInit,
    tarif => tarif.UrlPDFDocumentInformation
)

export const getApporteurCanUseRecueilBesoin = createSelector(
    initSelector,
    init => {
        const { immatOrias, formeJuridique, partenaires, villercs, nrc } = init.apporteur
        return Boolean(immatOrias && formeJuridique && partenaires && villercs && nrc)
    }
)

export const getHonorairesMaxiApporteur = createSelector(
    getTarifInit,
    tarif => tarif && tarif.Police ? tarif.Police.HonorairesMaxi : 20
)

export const getTauxApporteurMaxiApporteur = createSelector(
    getTarifInit,
    tarif => tarif && tarif.Police ? tarif.Police.TauxApporteur1Maxi : 20
)


/* Devoir Conseil */
export const getDevoirConseilDemandeTarifInit = createSelector(
    initSelector,
    init => init.data.DemandeTarif.DevoirConseil || {}
)
export const getDevoirVolDevoirConseilDemandeTarifInit = createSelector(
    getDevoirConseilDemandeTarifInit,
    devoirConseil => devoirConseil.DevoirIC !== undefined ? String(devoirConseil.DevoirIC) : undefined
)

/* Acte Gestion */
export const getActeGestionDemandeTarifInit = createSelector(
    initSelector,
    init => init.data.DemandeTarif.ActeGestion
)

/* Apporteur1 */
export const getCodeBelairApporteur1Init = createSelector(
    initSelector,
    init => init.apporteur1.codeBelair
)

export const getGestionnaireInit = createSelector(
    initSelector,
    init => init.gestionnaire
)

/* Apporteur 2 */
export const getIsComparateurApporteur = createSelector(
    initSelector,
    init => _.includes(["67256", "100122", "101853", "393798"], init.apporteur2?.codeBelair)
)
