import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { change } from 'redux-form';
import formValueSelector from 'redux-form/lib/formValueSelector';
import { postDejaClient, POST_DEJA_CLIENT } from '../../../../../../redux/actions/app/dejaClient/dejaClient.actions';
import {
    getApporteur1Init, getApporteur2Init,
    getIsCourtier,
    getToken
} from '../../../../../../redux/selectors/init/init.selectors';
import { getLoadingEntity } from '../../../../../../redux/selectors/ui/ui.selectors';
import { getSouscriptionDisabledVehicule } from '../../../../../../redux/selectors/vehicule/vehicule.selectors';
import Vehicule from './Vehicule';
import {API_URL_WS_FORMULAIRE, APP_NAME} from '@amo/core/constants/constants.js'

const mapStateToProps = (state) => {
    const selector = formValueSelector('devis');
    return {
        marque: selector(state, `marqueVehicule`),
        sra: selector(state, `DemandeTarif[Vehicule][IdentifiantSRA]`),
        cylindree: selector(state, `cylindreeVehicule`),
        modele: selector(state, `modeleVehicule`),
        familleProduit: selector(state, `typeVehicule`),
        connu: selector(state, `DemandeDevis[ConnuComment]`),
        deja_client: selector(state, `deja_client`),
        apporteur1: getApporteur1Init(state),
        apporteur2: getApporteur2Init(state),
        idDejaClient: selector(state, 'DemandeTarif[ListePersonnes][0][NumeroClient]'),
        loadingDejaClient: getLoadingEntity(state, POST_DEJA_CLIENT),
        vehiculeIsDisabled: getSouscriptionDisabledVehicule(state),
        queryDejaClient: `${API_URL_WS_FORMULAIRE}/fill/${getToken(state)}`,
        dejaClientValide: selector(state, 'DemandeTarif[ListePersonnes][0][DejaClient]') === 'true',
        isCourtier: getIsCourtier(state),
        dejaClientNom: selector(state, 'DemandeTarif[ListePersonnes][0][DejaClient]') === 'true' ? selector(state, 'DemandeTarif[ListePersonnes][0][Nom]') : null,
        dejaClientPrenom: selector(state, 'DemandeTarif[ListePersonnes][0][DejaClient]') === 'true' ? selector(state, 'DemandeTarif[ListePersonnes][0][Prenom]') : null,
        dejaClientBirthdate: selector(state, 'DemandeTarif[ListePersonnes][0][DejaClient]') === 'true' ? selector(state, 'DemandeTarif[ListePersonnes][0][DateNaissance]') : null,
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            changeValue: (field, value) => change('devis', field, value),
            postDejaClient,
        },
        dispatch,
    );

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { idDejaClient, queryDejaClient } = stateProps
    const { postDejaClient } = dispatchProps;

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        postDejaClient: () => postDejaClient({idDejaClient, query: queryDejaClient})
    };
};

const VehiculeContainer = compose(connect(mapStateToProps, mapDispatchToProps, mergeProps))(Vehicule);

export default VehiculeContainer;
